/* Login.css */
.login-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/img/pic.jpg');
  background-size: cover;
  background-position: center;
  backdrop-filter: saturate(200%) blur(100px); /* Add this line for the glass effect */
  background-color: rgba(255, 255, 255, 0.15); /* Add this line for a semi-transparent white background */
  }
